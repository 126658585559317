import axios from 'axios';

const API_URL = `https://86cwdq5aaf.execute-api.us-east-1.amazonaws.com/dev`;
const API_KEY = `7ta7RymkcdoDMLwHbZcV21hxdm0g8J42MGO5kAAc`;

const signIn = async email => {
  const params = {
    url: `${API_URL}/signin`,
    method: 'POST',
    data: { email },
    headers: {
      'x-api-key': API_KEY,
    },
  };

  try {
    const { data = {} } = await axios(params);
    const { result = {} } = data;
    console.log(result);
    const { user_id } = result;
    localStorage.setItem('nomadland-user', user_id);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createTrip = async trip_request => {
  const params = {
    url: `${API_URL}/trips`,
    method: 'POST',
    data: trip_request,
    headers: {
      'x-api-key': API_KEY,
      'nomadland-user': localStorage.getItem('nomadland-user'),
    },
  };

  try {
    const { data = {} } = await axios(params);
    const { result = {} } = data;

    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchTrips = async () => {
  const params = {
    url: `${API_URL}/trips`,
    method: 'GET',
    headers: {
      'x-api-key': API_KEY,
      'nomadland-user': localStorage.getItem('nomadland-user'),
    },
  };

  try {
    const { data = {} } = await axios(params);
    const { result = {} } = data;
    console.log(result);

    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchTrip = async trip_id => {
  const params = {
    url: `${API_URL}/trips/${trip_id}`,
    method: 'GET',
    headers: {
      'x-api-key': API_KEY,
      'nomadland-user': localStorage.getItem('nomadland-user'),
    },
  };

  try {
    const { data = {} } = await axios(params);
    const { result = {} } = data;
    console.log(result);

    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const showErrorMessage = (toast, message) => {
  toast({
    title: 'Woops!',
    description: message,
    status: 'error',
    duration: 5000,
    isClosable: true,
  });
};

const signOut = () => {
  localStorage.clear();
};

export default {
  signIn,
  signOut,
  fetchTrips,
  showErrorMessage,
  createTrip,
  fetchTrip,
};
