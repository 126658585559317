/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Flex,
  Button,
  Text,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react';
import { HamburgerIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { useLocation, useHistory, Link } from 'react-router-dom';

const Header = ({ authenticated, onSignOut }) => {
  // Hooks
  const location = useLocation();
  const history = useHistory();
  const [isMobileDevice] = useMediaQuery('(max-width: 768px)');

  // Render Methods
  const renderScheduleItem = () => (
    <Link to="/trips">
      <Button border="1px" rounded="full">
        <Text color="black" fontSize={['xs', 'md']}>
          Trips
        </Text>
      </Button>
      authenticated
    </Link>
  );

  const renderHamburgerMenu = () => (
    <Menu size="2xl">
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        size="lg"
        borderRadius="md"
        variant="outline"
        color="black"
        _hover={{ color: 'black', backgroundColor: 'gray.100' }}
        _active={{ color: 'black', backgroundColor: 'gray.100' }}
      />
      <MenuList zIndex="3">
        {/* Classes */}
        {/* <MenuItem onClick={() => history.push('/classes') || isNoAuthSignup}>
          <Text
            fontWeight={
              location.pathname.startsWith('/classes') || isNoAuthSignup ? 'bold' : 'normal'
            }
            color="black"
          >
            Classes
          </Text>
        </MenuItem> */}

        {/* Courses */}
        <MenuItem onClick={() => history.push('/trips')}>
          <Text
            fontWeight={location.pathname.startsWith('/trips') ? 'bold' : 'normal'}
            color="black"
          >
            Trips
          </Text>
        </MenuItem>

        {/* Courses */}
        <MenuItem onClick={onSignOut}>
          <Text fontWeight="normal" color="black">
            Sign Out
          </Text>
        </MenuItem>
      </MenuList>

      {/* Schedule */}
      {/* <MenuItem onClick={() => history.push('/schedule')}>
          <Text
            fontWeight={location.pathname.startsWith('/schedule') ? 'bold' : 'normal'}
            color="black"
          >
            Schedule
          </Text>
        </MenuItem> */}
    </Menu>
  );

  return (
    <Box position="relative" zIndex="3">
      <Flex
        as="nav"
        bg={location.pathname.includes('/courses') ? '#F5F5F5' : 'transparent'}
        align="center"
        justify="space-between"
        wrap="wrap"
        pt={[5, 8]}
        pb={[3, 4]}
        px={[5, 8]}
        color="white"
      >
        <HStack>
          <Link to="/trips">
            <Text color="black">Nomadland ™️</Text>
          </Link>
        </HStack>

        {authenticated && !isMobileDevice && (
          <HStack spacing={[1, 2]} mt={2}>
            {/* {renderSavedItem()} */}
            <Button onClick={onSignOut} color="black">
              Sign Out
            </Button>
          </HStack>
        )}
        {authenticated && isMobileDevice && renderHamburgerMenu()}
      </Flex>
      {/* <Flex w="100%" justifyContent="center" pt={1} pb={3}>
          {enableFilters && inAllClassesSection && renderFilterOptions()}
        </Flex> */}
    </Box>
  );
};

export default Header;
