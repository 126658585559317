import logo from './logo.svg';
import './App.css';
import { Text, Box, Input, FormControl, Button, VStack } from '@chakra-ui/react';

const SignIn = ({ onSignIn, email, setEmail, loading }) => (
  <VStack spacing={10}>
    <Text>Sign In</Text>
    <VStack maxW="md">
      <FormControl>
        <Text fontWeight="bold">Email</Text>
        <Input value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
      </FormControl>
      <Button isLoading={loading} rounded="md" onClick={onSignIn}>
        Sign In
      </Button>
    </VStack>
  </VStack>
);

export default SignIn;
