import logo from './logo.svg';
import './App.css';
import { ChakraProvider, useToast } from '@chakra-ui/react';
import Header from './Header';
import Trips from './Trips';
import Login from './Login';
import Trip from './Trip';

import { Switch, BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { useState } from 'react';
import actions from './actions';

const App = () => {
  const [authenticated, setAuthenticated] = useState(localStorage.getItem('nomadland-user'));
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const onSignIn = async () => {
    setLoading(true);
    try {
      const { user_id } = await actions.signIn(email);
      console.log(user_id);
      setAuthenticated(user_id);
    } catch (err) {
      actions.showErrorMessage(toast, err.message);
    }
    setLoading(false);
  };

  const onSignOut = () => {
    actions.signOut();
    setAuthenticated(null);
  };

  return (
    <div className="App">
      <ChakraProvider>
        <Router>
          <Header authenticated={authenticated} onSignOut={onSignOut} />
          {authenticated ? (
            <Switch>
              <Route exact path="/trips" render={props => <Trips {...props} />} />
              <Route exact path="/trips/:trip_id" render={props => <Trip {...props} />} />

              <Redirect to="/trips" />
            </Switch>
          ) : (
            <Switch>
              <Route
                path="/login"
                render={props => (
                  <Login
                    onSignIn={onSignIn}
                    email={email}
                    setEmail={setEmail}
                    loading={loading}
                    {...props}
                  />
                )}
              />
              <Redirect to="/login" />
            </Switch>
          )}
        </Router>
      </ChakraProvider>
    </div>
  );
};

export default App;
