import logo from './logo.svg';
import './App.css';
import {
  Spinner,
  Text,
  useToast,
  VStack,
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalBody,
  FormControl,
  Input,
  useDisclosure,
  Stack,
  ModalFooter,
  Box,
  Divider,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import actions from './actions';
import { Link } from 'react-router-dom';

const Trips = () => {
  const [trips, setTrips] = useState([]);
  const [createTripRequest, setCreateTripRequest] = useState({});

  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchTrips = async () => {
    setLoading(true);
    try {
      const { trips: t = [] } = await actions.fetchTrips();
      setTrips(t);
    } catch (err) {
      actions.showErrorMessage(toast, err.message);
    }
    setLoading(false);
  };

  const createTrip = async () => {
    setLoading(true);
    try {
      await actions.createTrip(createTripRequest);
      await fetchTrips();
      onClose();
      setCreateTripRequest({});
    } catch (err) {
      actions.showErrorMessage(toast, err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTrips();
  }, []);

  const renderCreateTripModal = () => (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Trip</ModalHeader>

        <ModalBody>
          <Stack>
            <FormControl>
              <Text>Start Date</Text>
              <Input
                value={createTripRequest.start}
                onChange={e =>
                  setCreateTripRequest(prevRequest => {
                    if (!prevRequest) prevRequest = {};

                    prevRequest.start = e.target.value;
                    return prevRequest;
                  })
                }
                placeholder="YYYY-MM-DD"
              />
            </FormControl>
            <FormControl>
              <Text>End Date</Text>
              <Input
                value={createTripRequest.end}
                onChange={e =>
                  setCreateTripRequest(prevRequest => {
                    if (!prevRequest) prevRequest = {};

                    prevRequest.end = e.target.value;
                    return prevRequest;
                  })
                }
                placeholder="YYYY-MM-DD"
              />
            </FormControl>
            <FormControl>
              <Text>Country</Text>
              <Input
                value={createTripRequest.country}
                onChange={e =>
                  setCreateTripRequest(prevRequest => {
                    if (!prevRequest) prevRequest = {};

                    prevRequest.country = e.target.value;
                    return prevRequest;
                  })
                }
                placeholder="Country"
              />
            </FormControl>
            <FormControl>
              <Text>City</Text>
              <Input
                value={createTripRequest.city}
                onChange={e =>
                  setCreateTripRequest(prevRequest => {
                    if (!prevRequest) prevRequest = {};

                    prevRequest.city = e.target.value;
                    return prevRequest;
                  })
                }
                placeholder="City"
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button isLoading={loading} color="white" ml={2} bg="green.500" onClick={createTrip}>
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <VStack>
      {isOpen && renderCreateTripModal()}
      <VStack>
        <Text>My Trips</Text>
        <Divider />
        <VStack>
          {loading && <Spinner />}
          {!loading && trips.length === 0 && <Text>No trips yet!</Text>}
          {!loading && (
            <Button w="100%" isLoading={loading} onClick={onOpen}>
              Create Trip
            </Button>
          )}
          {trips.map(t => (
            <Link to={`/trips/${t.id}`}>
              <Box w="100%" p={3} border="1px" rounded="lg">
                <VStack>
                  <Text fontWeight="bold">{t.location}</Text>
                  <Text>
                    {t.start} - {t.end}
                  </Text>
                </VStack>
              </Box>
            </Link>
          ))}
        </VStack>
      </VStack>
    </VStack>
  );
};

export default Trips;
