import logo from './logo.svg';
import './App.css';
import {
  Spinner,
  Text,
  useToast,
  VStack,
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalBody,
  FormControl,
  Input,
  useDisclosure,
  Stack,
  ModalFooter,
  Divider,
  HStack,
  Image,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import actions from './actions';
import { useParams } from 'react-router';

const Trip = () => {
  const [trip, setTrip] = useState({});
  const [members, setMembers] = useState([]);
  const [reccomended, setReccomended] = useState([]);

  const { trip_id } = useParams();

  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const fetchTrip = async () => {
    setLoading(true);
    try {
      const {
        trip: t = [],
        reccomended: r = [],
        members: m = [],
      } = await actions.fetchTrip(trip_id);
      setTrip(t);
      setReccomended(r);
      setMembers(m);
    } catch (err) {
      actions.showErrorMessage(toast, err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTrip();
  }, []);

  return (
    <VStack>
      <VStack maxW="md">
        {loading && <Spinner />}
        {!loading && (
          <VStack spacing={5}>
            <Text>Trip to:</Text>
            <Text fontSize="xl" fontWeight="bold">
              {trip.location}
            </Text>

            <Divider />
            <HStack>
              <Text>Arrive:</Text>
              <Text fontWeight="bold">{trip.start}</Text>
            </HStack>
            <HStack>
              <Text>Depart:</Text>
              <Text fontWeight="bold">{trip.end}</Text>
            </HStack>
            <Divider />
            <VStack>
              <Text fontWeight="bold" fontSize="lg">
                Community Members
              </Text>
              {members.length === 0 && <Text>No members are in town during your trip 🙃</Text>}
              {members.map(m => (
                <VStack w="100%" p={3} border="1px" rounded="lg">
                  <HStack>
                    <Image maxW="50px" rounded="full" src={m.avatar_url} />
                    <Stack textAlign="left">
                      <Text fontWeight="bold">{m.first_name}</Text>
                      <Text>{m.overlap}</Text>
                    </Stack>
                  </HStack>
                </VStack>
              ))}
            </VStack>
            <Divider />
            <VStack w="100%">
              <Text fontWeight="bold" fontSize="lg">
                Reccomended AirBnBs
              </Text>
              {reccomended.map(r => (
                <VStack w="100%" p={3} border="1px" rounded="lg">
                  <HStack>
                    <Text>Capacity:</Text>
                    <Text fontWeight="bold">{r.capacity}</Text>
                  </HStack>
                  <HStack>
                    <Text>Bedrooms:</Text>
                    <Text fontWeight="bold">{r.bedrooms}</Text>
                  </HStack>
                  <HStack>
                    <Text>Price (Per Night):</Text>
                    <Text fontWeight="bold">{r.price}</Text>
                  </HStack>
                  <HStack>
                    <Text>Speed (Mbps):</Text>
                    <Text fontWeight="bold">{r.speed}</Text>
                  </HStack>

                  <a style={{ textDecoration: 'underline' }} href={r.url} target="_blank">
                    View Listing
                  </a>
                </VStack>
              ))}
            </VStack>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

export default Trip;
